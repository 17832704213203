import React from 'react';
import Link from '/src/components/LocalizedLink';
import Layout from '../../components/Layout';
import IconWhatsApp from '../../assets/images/svg/whatsapp.inline.svg';

import '../../assets/styles/pages/terms-and-privacy-page.scss';

const CompetitionTerms = () => {
  return (
    <Layout
      title="TEFL Iberia 10th Birthday Competition Terms and Conditions"
      description="Find out more about TEFL Iberia 10th Birthday Competition Terms and Conditions."
    >
      <div className="terms-and-privacy-page">
        <div className="container">
          <div className="wrapper">
            <h1 className="c-title-42">
              TEFL Iberia 10th Birthday Competition Terms and Conditions
            </h1>

            <div className="subsection">
              <ol className="subsection__list subsection__list--givaway">
                <li className="item">
                  <strong>Eligibility:</strong> The 10th birthday giveaway is open only to those who
                  sign up at the giveaway page located at{' '}
                  <Link className="red-link" to="/10th-birthday-course-giveaway">
                    https://tefl-iberia.com/10th-birthday-course-giveaway
                  </Link>{' '}
                  and who are above 18 years of age as of the date of entry. The giveaway is open
                  worldwide and is void where prohibited by law. The giveaway is subject to all
                  applicable federal, state and local laws and regulations. Void where prohibited.{' '}
                  <br />
                  <br />
                  The winner will be subjected to TEFL Iberia’s interview process and must meet the
                  minimum requirements to enter the course and must be approved by the TEFL Iberia
                  team. The winner must hold a minimum of C1 level of English and pass an applicant
                  interview conducted by a member of the TEFL Iberia team.{' '}
                </li>
                <li className="item">
                  <strong>Agreement to Rules:</strong> By participating, you agree to be fully
                  unconditionally bound by these Rules, and you represent and warrant that you meet
                  the eligibility requirements set forth herein. In addition, you agree to accept
                  the decisions of TEFL Iberia, as final and binding as it relates to the content.
                  The giveaway is subject to all applicable federal, state and local laws.
                </li>
                <li className="item">
                  <strong>Giveaway Period:</strong> Entries will be accepted online starting on or
                  about June 13th 2022 and ending on July 15th 2022. All online entries must be
                  received by July 15th 2022, 11:59PM GMT+2
                </li>
                <li className="item">
                  <strong>How to Enter:</strong> The giveaway must be entered by submitting an entry
                  using the online form provided on this site, located at{' '}
                  <Link className="red-link" to="/10th-birthday-course-giveaway">
                    https://tefl-iberia.com/10th-birthday-course-giveaway
                  </Link>{' '}
                  The entry must fulfill all giveaway requirements, as specified, to be eligible to
                  win a prize. Entries that are not complete or do not adhere to the rules or
                  specifications may be disqualified at the sole discretion of TEFL Iberia. You may
                  enter only once and you must fill in the information requested. You may not enter
                  more times than indicated by using multiple email addresses, identities or devices
                  in an attempt to circumvent the rules. If you use fraudulent methods or otherwise
                  attempt to circumvent the rules your submission may be removed from eligibility at
                  the sole discretion of TEFL Iberia
                </li>
                <li className="item">
                  <strong>Prizes:</strong> The Winner will receive a Trinity CertTESOL course
                  (valued at €1650) and up to 12 months on TEFL Iberia’s Developing Teacher course
                  (valued at €1200). Actual/appraised value may differ at time of prize award. No
                  cash or other prize substitution is permitted. The prize is non transferable. Any
                  and all prize related expenses, including without limitation any and all federal,
                  state, and/or local taxes shall be the sole responsibility of the winner. No
                  substitution of prize or transfer/assignment of prize to others or request for the
                  cash equivalent by winners is permitted. Acceptance of prize constitutes
                  permission for TEFL Iberia to use the winner's name, likeness, and entry for
                  purposes of advertising and trade without further compensation, unless prohibited
                  by law.
                </li>
                <li className="item">
                  <strong>Prize Claim:</strong> Once accepted, the Winner will have 24 months in
                  which to start their course.
                </li>
                <li className="item">
                  <strong>Odds:</strong> The odds of winning depend on the number of eligible
                  entries received.
                </li>
                <li className="item">
                  <strong>Winner selection and notification:</strong> Winner of the give away will
                  be selected in a random drawing. Winners will be notified via email to the email
                  address they entered the giveaway with, within five (5) days following the winner
                  selection. TEFL Iberia shall have no liability for a winner's failure to receive
                  notices due to winners' spam, junk e-mail or other security settings or for
                  winners' provision of incorrect or otherwise non-functioning contact information.
                  If the selected winner cannot be contacted, is ineligible, fails to claim the
                  prize within 72 hours from the time award notification was sent, or fails to
                  timely return a completed and executed declaration and releases as required, prize
                  may be forfeited and an alternate winner selected.
                </li>
                <li className="item">
                  <strong> Terms:</strong> By entering the Sweepstakes you agree to receive email
                  newsletters periodically from <b>TEFL Iberia</b>. You can opt-out of receiving
                  this communication at any time by clicking the unsubscribe link in the newsletter.
                </li>
                <li className="item">
                  <strong>Privacy Policy:</strong> Information submitted with an entry is subject to
                  the Privacy Policy stated on the TEFL Iberia website. To read the Privacy Policy,{' '}
                  <Link to="/web/privacy/" className="red-link">
                    click here.
                  </Link>
                </li>
                <li className="item">
                  This promotion is in no way sponsored, endorsed or administered by, or associated
                  with Facebook, Twitter, Instagram, Snapchat, Youtube, Pinterest, LinkedIn or
                  Google. You understand that you are providing your information to the owner of
                  this giveaway and not to Facebook, Twitter, Instagram, Snapchat, Youtube,
                  Pinterest, LinkedIn or Google.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CompetitionTerms;
